.success-modal {
    .custom-modal {
        &__container {
            width: 446px;
            top: 50%;
            bottom: auto;
            transform: translate(-50%, -50%);
        }

        &__main {
            padding-top: 0;
        }

        &__footer {
            justify-content: center;
        }
    }
    .custom-modal_animated{
        z-index: 6;
    }
}
