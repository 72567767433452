@import "../../../../variables.scss";

.add-edit-collaborator-modal {
    .custom-modal__container {
        width: 801px;
    }

    &__input-container {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        .phone-input-error-text {
            position: absolute;
            font-size: 14px;
            color: red;
            top: 0px;
            right: 0px;

            p {
                font-size: 14px;
                color: red;
            }
        }

        .phone-input-error {
            border: 2px solid $lightRed;
            text-decoration: underline dotted $lightRed;
        }
    }

    &__input-name {
        color: #2e2b2b;
        opacity: 0.5;
        font-size: 13px;
        margin-bottom: 8px;
    }

    &__city {
        width: 481px;
    }

    &__zip {
        width: 245px;
    }

    &__prefix {
        width: 125px;
    }

    &__phone {
        width: 597px;
    }

    &__role {
        width: 536px;
    }

    .PhoneInput {
        width: 100%;
        height: 40px;
        padding-left: 5px;

        width: 100%;
        height: 100%;
        border-radius: 3px 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
            width: 100%;
            height: 100%;
            padding: 9px;
            border-radius: 4px;
            background-color: $lightGrey;
        }
    }
}
