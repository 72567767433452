.type-modal {
    .custom-modal {
        &__container {
            width: 446px;
            top: 50%;
            bottom: auto;
            transform: translate(-50%, -50%);
        }
    }

    &__label {
        font-size: 14px;
        color: #2e2b2b;
        opacity: 0.5;
        margin-bottom: 5px;
    }

    &__dropdown {
        height: 40px;
    }
}
