@import "../../variables.scss";

.custom-input {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_error {
    border: 2px solid $lightRed;
    text-decoration: underline dotted $lightRed;
  }

  &__input {
    width: 100%;
    height: 100%;
    padding: 9px;
    border-radius: 4px;
    background-color: $lightGrey;
    &:disabled {
      cursor: not-allowed;
    }
  }

  &__image {
    position: absolute;
    top: 10px;
    right: -18px;
    height: 20px;
    opacity: 0;
    transition: 0.5s;
    &_animated {
      opacity: 1;
      transition: 0.5s;
    }
  }

  &__err {
    // position: absolute;
    font-size: 12px;
    color: red;
    display: flex;
    p {
      color: red;
      &:first-child {
        margin-right: 5px;
      }
    }

    &-container {
      min-width: 160px;
      position: absolute;
      top: 0;
      left: 108%;
      //border: 1px solid black;
      border-radius: 3px;
      padding: 7px;
      opacity: 0;
      transition: 0.5s;
      box-shadow: 3px 3px 7px #00000017;
      border: 1px solid #70707012;
      &_animated {
        opacity: 1;
        transition: 0.5s;
      }
    }

    &-text {
      font-size: 13px;
      white-space: nowrap;
      p {
        color: #000000;
        opacity: 0.5;
      }
    }

    &.topright {
      top: -28px;
      right: 0px;
    }

    &.bottomleft {
      bottom: -28px;
      left: 0px;
    }
  }

  .custom-input-icon-container {
    height: 100%;
    padding: 0 13px;
    background-color: $lightGrey;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      opacity: 0.5;
    }
  }

  .custom-input-icon-container__white {
    height: 100%;
    padding: 0 13px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      opacity: 0.5;
    }
  }

  .custom-input-icon {
    width: 13px;
    height: 13px;
  }
}
