@import '../../../variables.scss';

.space {
  display: flex;
  width: 100%;
  height: 100%;

  &__preview-container {
    overflow: hidden;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 120px);
    background-color: grey;
    position: relative;

    img {
      width: 100%;
    }
  }

  &__preview-play-btn {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    img {
      width: 60%;
      height: 60%;
      margin-left: 10px;
      transition: 0.2s;
    }

    &:hover {
      img {
        transform: scale(1.1);
        transition: 0.2s;
      }
    }
  }

  .space-sidebar {
    position: fixed;
    width: 50px;
    height: calc(100vh - 70px);
    left: 0;
    top: 70px;
    background-color: #fff;
    box-shadow: 0px 3px 25px #0000000f;
    padding-left: 15px;
    padding-top: 20px;
    transition: 0.2s;
    z-index: 5;

    &_opened {
      width: 240px;
      transition: 0.2s;
    }

    &__name {
      color: $black;
      margin: 11px 0 25px 0;
      font-size: 24px;
      font-weight: 600;
      height: 35px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__item {
      margin-top: 20px;
      display: flex;
      overflow: hidden;
    }

    &__item-icon {
      cursor: pointer;
      height: 20px;
      min-width: 25px;
    }

    &__item-title {
      cursor: pointer;
      color: $black;
      font-size: 13px;
      font-weight: 500;
      margin-left: 10px;
      white-space: nowrap;
      opacity: 0.5;
      display: none;

      &_active {
        opacity: 1;
      }

      &_opened {
        display: block;
      }

      &_hovered {
        display: block;
        background-color: #fff;
        height: 20px;
        padding: 0 10px 0 20px;
        position: relative;
        left: -10px;
        color: #000;
        opacity: 1;
        z-index: 2;
      }
    }

    &__openbtn {
      margin-top: 76px;
      cursor: pointer;
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;

      &-icon {
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(-90deg);
        transition: 0.2s;
        margin-right: 10px;

        &_opened {
          transition: 0.2s;
          transform: rotate(90deg);
        }
      }
    }
  }

  .space-module {
    width: 538px;
    height: calc(100vh - 95px);
    overflow-y: auto;
    margin-left: 50px;
    padding: 0 20px 20px 0;
    position: relative;

    &__close-module-btn {
      position: absolute;
      cursor: pointer;
      top: 5px;
      right: 20px;
      font-size: 20px;
      transition: 0.2s;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;

      &:hover {
        transform: scale(1.2);
        transition: 0.2s;
      }
    }
    &--width {
      width: 700px;
    }
  }

  &__iframe-container {
    position: relative;
    &--hide {
      overflow: hidden;
    }
  }
  &__iframe-container--position {
  }
  &-floor {
    position: absolute;
    padding: 10px 16px;
    grid-gap: 8px;
    background: #fff;
    display: flex;
    flex-direction: column;
    z-index: 1;
    transform: translateY(-50%);
    top: 50%;
    &__item {
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      &--active {
      }
    }
  }
}

body {
  height: 100vh;
  width: 100%;
  margin: 0;
  overflow: hidden;
}

#iframe-button {
  position: absolute;
  z-index: 5;
  display: none;
  cursor: pointer;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 5px solid #ded9d6;
  box-shadow: 0px 3px 15px #0000000f;
}
.iframe-hide {
  visibility: hidden;
}
