@import "../../../../variables.scss";

.add-edit-userFR-modal {
  .custom-modal_animated{
    z-index: 5;
  }
  .custom-chip{
    margin: 10px auto 0px;
  }
  .custom-modal__container {
    width: 476px;
    top: 50%;
    transform: translate(-50%, -50%)
  }

  &__input-container {
    width: 100%;
    margin-bottom: 10px;
    position: relative;

    .phone-input-error-text {
      position: absolute;
      font-size: 14px;
      color: red;
      top: 0px;
      right: 0px;

      p {
        font-size: 14px;
        color: red;
      }
    }

    .phone-input-error {
      border: 2px solid $lightRed;
      text-decoration: underline dotted $lightRed;
    }
  }

  &__input-name {
    color: #2e2b2b;
    opacity: 0.5;
    font-size: 13px;
    margin-bottom: 8px;
  }
  &__icon-wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 10px;
    margin-bottom: 8px;
    .add-edit-userFR-modal__input-name{
      margin-bottom: 0;
    }
  }
  &__input-tool{
    background: #f6f7f8;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
      cursor: pointer;
    }
    svg {
      width: 8px;
    }
  }

  &__city {
    width: 481px;
  }

  &__zip {
    width: 245px;
  }

  &__prefix {
    width: 125px;
  }

  &__phone {
    width: 597px;
  }

  .PhoneInput {
    width: 100%;
    height: 40px;
    padding-left: 5px;

    width: 100%;
    height: 100%;
    border-radius: 3px 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      width: 100%;
      height: 100%;
      padding: 9px;
      border-radius: 4px;
      background-color: $lightGrey;
    }
  }
}
