@import '../../variables.scss';

.custom-dropdown {
  position: relative;
  width: 100%;
  height: 100%;
  font-weight: 500;

  .label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &_error {
      border: 2px solid $lightRed;
      text-decoration: underline dotted $lightRed;
      // transition: 0.5s;
    }
  }

  .label {
    width: 100%;
    height: 100%;
  }

  .arrow {
    font-weight: bold;
    transform: rotate(90deg);
    transition: 0.2s;

    &_up {
      transform: rotate(-90deg);
      transition: 0.2s;
    }
  }

  .select {
    position: absolute;
    z-index: 1;
    background-color: #fff;
    max-height: 108px;
    overflow-y: auto;
    box-shadow: 0px 3px 15px #0000000f;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      background: transparent;
    }

    &__option {
      padding: 3px 13px 3px 13px;
      cursor: pointer;
      font-size: 14px;
      word-break: break-word;
      &:hover {
        background-color: $lightGrey;
      }
    }
  }

  .select.top {
    bottom: 30px;
  }

  &.white {
    .label {
      font-size: 14px;
      margin-right: 9px;
    }

    .select {
      width: calc(100% + 10px);
      padding: 17px 0;
      top: 30px;
    }
  }

  &.grey {
    .label {
      padding: 9px 0 9px 9px;
      background-color: $lightGrey;
      min-width: 51px;
    }

    .label-container {
      background-color: $lightGrey;
      height: 100%;
      color: $grey;
      padding-right: 5px;
    }

    .label {
      font-size: 13px;
    }

    .cancel-options {
      cursor: pointer;
      transition: 0.2s;
      color: #7d8d9b;
      margin-right: 10px;

      &:hover {
        transform: scale(1.2);
        transition: 0.2s;
      }
    }

    .arrow {
      color: #7d8d9b;
      margin-right: 10px;
    }

    .select {
      width: 100%;
      min-width: 150px;
      // padding: 10px 0;
      top: 40px;
    }
  }

  &__err {
    position: absolute;
    font-size: 14px;
    color: red;
    font-weight: 400;
    p {
      color: red;
      font-weight: 400;
    }

    &.topright {
      top: -28px;
      right: 0px;
    }

    &.bottomleft {
      bottom: -28px;
      left: 0px;
    }
  }
}
.custom-dropdown-checkmark {
  .select {
    background-color: #f6f7f8;
    top: 38px !important;
    border-top: 1px solid #707070;
    max-height: 248px;
    border-radius: 0 0 4px 4px;
    cursor: pointer;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
  }
  .select__option {
    padding: 14px 13px 14px 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .custom-checkbox {
    .label {
      display: none;
    }
  }
}
