.invite-modal {
    .custom-modal {
        &__container {
            width: 446px;
            top: 50%;
            bottom: auto;
            transform: translate(-50%, -50%);
        }
    }

    &__additional-text {
        color: #2e2b2b;
        opacity: 0.5;
        font-size: 13px;
        margin-bottom: 10px;
    }

    &__textarea {
        height: 150px;
        margin-bottom: 20px;
    }
}
