.dashboard {
    margin-top: 31px;

    &__slider-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 18px;
    }

    &__slider-title {
        font-size: 30px;
        font-weight: 600;
    }

    &__slider-wrapper {
        margin-bottom: 21px;
    }
}
