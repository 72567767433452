@import "../../index.scss";

.new-password-page {
    display: flex;
    justify-content: center;
    align-items: center;

    .main-block {
        width: 336px;
        margin-top: 64px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .logo {
        width: 336px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 100%;
        }
    }

    .form {
        margin-top: 142px;
        width: 289px;

        &__head {
            margin-bottom: 29px;
            font-size: 24px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__input {
            width: 100%;
            margin-bottom: 20px;
            height: 38px;
            border-radius: 4px;
            font-size: 13px;
        }

        &__button {
            width: 100%;
            margin-top: 20px;
        }
    }
}
