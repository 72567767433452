.custom-breadcrumbs {
    height: 19px;
    display: flex;

    &__block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        opacity: 0.5;
        transition: 0.2s;

        &:hover {
            opacity: 1;
            transform: scale(1.1);
            transition: 0.2s;
        }

        &:first-child {
            opacity: 1;
        }
    }

    &__text {
        font-size: 13px;
    }

    &__arrow {
        width: 13px;
        height: 12px;
        transform: rotate(-85deg);
        margin-right: 7px;
        margin-left: 5px;
    }
}
