.manage-col-modal {
  .modal-settings-space {
    .custom-modal {
      &__container {
        width: 697px;
      }

      &__main {
        padding: 40px 30px 60px;
      }
    }
  }

  &__main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .form-item {
    &-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &.first {
        margin-bottom: 37.5px;
      }
    }

    &-label {
      opacity: 0.5;
    }

    &-dropdown,
    &-input {
      width: 432px;
    }
  }
}
