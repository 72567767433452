@import '../../variables.scss';

.home-page {
  .header {
    width: 100%;
    height: 70px;
    box-shadow: 0px 3px 15px #0000000f;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__logo {
      margin: 0 63px 0 26px;
      width: 156px;
      height: 37px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 1250px) {
        margin: 0 20px 0 15px;
      }

      img {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;
      }
    }

    &__tab {
      display: flex;
      align-items: center;
      height: 100%;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0px;
      color: #2e2b2b;
      cursor: pointer;
      white-space: nowrap;

      &:first-child {
        margin-left: 0;
      }
    }

    &__user-info {
      display: flex;
      align-items: center;
      margin-left: 100px;

      @media (max-width: 1350px) {
        margin-left: 50px;
      }

      @media (max-width: 1280px) {
        margin-left: 30px;
      }
    }

    &__avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__profile-dropdown {
      max-width: 180px;
      margin: 0 29px 0 15px;
      position: relative;
      font-size: 14px;
      font-weight: 500;
    }

    &__profile-dropdown-button {
      cursor: pointer;
      display: flex;
      height: 50px;
      align-items: center;

      .arrow {
        font-weight: bold;
        transform: rotate(90deg);
        transition: 0.2s;
        margin-left: 5px;
        font-size: 16px;

        &_up {
          transform: rotate(-90deg);
          transition: 0.2s;
        }
      }
    }

    &__profile-dropdown-title {
      cursor: pointer;
    }

    &__profile-select {
      right: 8px;
      width: 170px;
      height: 60px;
      position: absolute;
      background-color: #fff;
      box-shadow: 0px 3px 15px #0000000f;
      z-index: 5;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__profile-select-item {
      padding: 15px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      cursor: pointer;

      &:hover {
        background-color: $lightGrey;
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }

    &__lang-dropdown {
      width: 70px;
      margin-right: 31px;
    }
  }

  .home-main {
    width: 100%;
    height: calc(100vh - 70px);
    padding: 25px 23px 0 23px;
    overflow: auto;
  }

  .subheader {
    display: flex;
    align-items: center;
    height: 51px;
    justify-content: space-between;

    &__title {
      font-size: 36px;
      font-weight: 600;
    }

    &__button {
      width: 52px;
      height: 52px;
      position: relative;
    }

    &__button-item {
      box-shadow: 0px 3px 15px #0000000f;
      width: 52px;
      height: 52px;
      overflow: hidden;
      border: none;
      background-color: #fff;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      border: 1px solid #fff;

      &:hover {
        border: 1px solid rgba(201, 192, 192, 0.5);
        transform: scale(1.2);
        transition: 0.3s;
      }
    }

    &__plus {
      object-fit: cover;
      width: 20px;
      height: 23px;
    }

    &__select {
      width: 127px;
      height: 85px;
      background-color: #fff;
      position: absolute;
      z-index: 1;
      right: 0;
      top: 65px;
      border: 1px solid $lightGrey;
      box-shadow: 0px 3px 15px #0000000f;
      border-radius: 5px;
    }

    &__select-option {
      padding: 10px 20px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;

      &:hover {
        background-color: $lightGrey;
      }
    }
  }

  .wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    margin-bottom: 47px;
  }

  .search {
    width: 289px;
    height: 38px;
  }
}
