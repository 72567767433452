@import '../../variables.scss';

.custom-button {
  width: 100%;
  height: 41px;
  border-radius: 4px;
  overflow: hidden;

  .button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
    outline: none;
    border: none;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0px 3px 15px #0000000f;

    &_disabled {
      opacity: 0.7;
    }
  }

  .green {
    color: #fff;
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }
  }

  .grey {
    background-color: $grey;
    color: black;

    &:hover {
      background-color: #bdb7b7;
    }
  }

  .plus {
    width: 20px;
    height: 23px;
  }
}
.sizeSmall {
  height: 32px;
}
