@import "../../variables.scss";

.slider-wrapper {
    .slider-block {
        height: 282px;
        width: 325px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;

        &__container {
            box-shadow: 0px 3px 15px #0000000f;
            width: 317px;
            height: 100%;
            border-radius: 5px;
        }

        &__image {
            background-color: #909090;
            height: 187px;
            width: 100%;
            border-radius: 5px 5px 0 0;
        }

        &__name {
            font-size: 18px;
            font-weight: 600;
            color: #2e2b2b;
            margin: 20px 20px 6px 20px;
        }

        &__info {
            font-size: 16px;
            color: #2e2b2b;
            opacity: 0.5;
            margin: 0 20px 20px 20px;
        }
    }

    .slick-dots {
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);

        li {
            margin: 0;
            padding: 0;
            width: 26px;

            &.slick-active {
                button {
                    &:before {
                        background-color: $lightGreen;
                    }
                }
            }

            button {
                width: 100%;

                &:before {
                    opacity: 1;
                    content: "";
                    width: 100%;
                    height: 7px;
                    background-color: #f2f2f2;
                }
            }

            &:first-child {
                button {
                    &::before {
                        border-radius: 10px 0 0 10px;
                    }
                }
            }

            &:last-child {
                button {
                    &::before {
                        border-radius: 0 10px 10px 0;
                    }
                }
            }
        }
    }
}
