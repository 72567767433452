.custom-slider{
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 5px;
  padding:0 10px;
  &__step{
    color: #2E2B2B;
    opacity: 0.5;
    font-size: 13px;
    &--right{
      position: absolute;
      bottom: -10px;
      right: 0;
    }
    &--left {
      position: absolute;
      bottom: -10px;
      left: 0;
    }
  }
}