@import '../../variables.scss';

.custom-upload-image {
  width: 495px;
  margin-bottom: 18px;

  &__label {
    font-size: 20px;
    font-weight: 600;
    color: $black;
    margin-bottom: 11px;
  }

  &__upload {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 67px;
    width: 212px;
    background: $lightGrey;
    border-radius: 3px;
    font-size: 16px;
    color: $black;

    &-container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &-btn {
      border: none;
      cursor: pointer;

      &:hover {
        background: $grey;
      }
    }

    &-img {
      width: auto;
      height: 67px;

      &-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 212px;
      }
    }

    &-image {
      width: auto;
      max-width: 212px;
      height: 100%;
      object-fit: scale-down;
      object-position: center;
      border-radius: 4px;
    }

    &-icon {
      width: 100%;
      height: 50px;
      opacity: 0.07;

      &-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 13px;
      }
    }

    &-clean {
      width: 100%;
      height: 100%;

      &-btn {
        position: absolute;
        right: 0;
        bottom: -20px;
        width: 14px;
        height: 16px;
        padding: 0;
        background-color: transparent;
        border: none;
        cursor: pointer;
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
