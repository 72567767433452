.custom-loader {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 8;

    .background {
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.1;
    }

    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
