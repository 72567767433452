@import '../../variables.scss';

.custom-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: auto;
  z-index: 6;
  overflow-y: auto;
  opacity: 0;
  transition: 0.5s;

  &::-webkit-scrollbar {
    width: 0;
  }

  & {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }

  &_animated {
    opacity: 1;
    transition: 0.5s;
  }

  &__background {
    position: fixed;
    background-color: black;
    opacity: 0.2;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  &__container {
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 5px;
    margin-bottom: 28px;
    transition: 0.5s;

    &_animated {
      top: 28px;
      transition: 0.5s;
    }
  }

  &__header {
    border-radius: 5px 5px 0px 0px;
    color: #fff;
    padding: 14px 17px 14px 25px;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
  }

  &__subtitle {
    font-size: 16px;
    color: #fff;
    margin-top: 5px;
  }

  &__close-btn {
    width: 28px;
    height: 28px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    flex-shrink: 0;
    &:hover {
      border: 1px solid lightgrey;
    }
  }

  &__main {
    width: 100%;
    height: 100%;
    padding: 13px 30px 30px 30px;
    background-color: #fff;
    margin-bottom: -1px;
  }

  &__footer {
    border-radius: 0px 0px 5px 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 30px 30px 30px;
    background-color: #fff;
  }

  &__spacer {
    height: 28px;
    background-color: black;
    opacity: 0;
  }

  &__cancel-btn {
    min-width: 181px;
    min-height: 41px;
    margin-right: 30px;
  }

  &__submit-btn {
    min-width: 181px;
    min-height: 41px;
  }
}
