@import '../../index.scss';

.token-expired-page {
  display: flex;
  justify-content: center;
  align-items: center;

  .main-block {
    width: 336px;
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .logo {
    width: 336px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 100%;
    }
  }

  .form {
    margin-top: 142px;
    width: 289px;

    &__main-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__head {
      margin-bottom: 29px;
      font-size: 24px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    &__button {
      width: 100%;
      margin-top: 21px;
    }

    &__bottom-text {
      font-size: 13px;
      margin-top: 29px;
      width: 100%;
      display: flex;
      justify-content: center;

      span {
        font-weight: 600;
        cursor: pointer;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__arrow {
      font-size: 15px;
      margin-right: 12px;
    }

    &__img-wrapper {
      width: 59px;
      height: 59px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }

    &__success-img {
      width: 35px;
      height: 37px;
    }

    &__success-text {
      width: 262px;
      text-align: center;
      margin-top: 15px;
      font-size: 16px;
    }
  }

  .text {
    font-size: 13px;
  }
}
