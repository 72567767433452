@import "../../index.scss";

.sign-up-page {
    display: flex;
    justify-content: center;
    align-items: center;

    .main-block {
        width: 336px;
        margin-top: 64px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .logo {
        width: 336px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 100%;
        }
    }

    .form {
        margin-top: 86px;
        width: 289px;
        margin-bottom: 50px;

        &__head {
            margin-bottom: 29px;
        }

        &__title {
            font-size: 24px;
            font-weight: 600;
        }

        &__language {
            width: 92px;
            height: 20px;
        }

        &__input {
            width: 100%;
            margin-bottom: 20px;
            height: 38px;
            border-radius: 4px;
            font-size: 13px;
        }

        &__button {
            width: 100%;
            margin-top: 21px;
        }

        &__bottom-text {
            font-size: 13px;
            margin-top: 29px;
            width: 100%;
            display: flex;
            justify-content: center;

            span {
                font-weight: 600;
                cursor: pointer;
                margin-left: 5px;
            }
        }

        &__welcome {
            text-align: center;
        }

        &__welcome-title {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 9px;
        }

        &__welcome-text {
            font-size: 13px;
            letter-spacing: 0px;
            margin-bottom: 23px;
        }

        &__phone-wrapper {
            display: flex;
            justify-content: space-between;
        }

        &__country-code {
            width: 51px;
        }

        &__phone {
            width: 226px;
        }

        &__help-link {
            font-size: 16px;
            margin-top: 28px;
            margin-bottom: 15px;
            text-decoration: underline;
            width: 100%;
            text-align: center;
        }
    }

    .text {
        font-size: 13px;
    }
}
