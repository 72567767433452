.collaborators-page {
    width: 100%;
    margin-top: 30px;

    &__header-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__trash {
        width: 100%;
        height: 20px;
        display: grid;
        grid-template-columns: 1fr 27fr;
        transition: 0.2s;
        opacity: 0.5;

        &_image {
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0.5;
            width: 40px;
            img {
                cursor: pointer;
                height: 20px;

                &:hover {
                    transform: scale(1.2);
                    transition: 0.2s;
                }
            }
        }
    }

    .collaborators-table {
        width: 100%;
        font-size: 16px;
        min-height: calc(100vh - 350px);

        &__row {
            width: 100%;
            margin: 15px 0;
            cursor: pointer;
            min-height: 37px;
            display: grid;
            grid-template-columns: 1fr 3fr 3fr 3fr 3fr 3fr 3fr 3fr 3fr 2fr;
            grid-gap: 5px;

            &:hover {
                background-color: #f8f8f8;
            }
        }

        &__def-row {
            width: 100%;
            height: 23px;
            color: #2e2b2b;
            font-weight: 600;
            margin-bottom: 16px;
            &:hover{
                background: transparent;
            }
        }

        &__message {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__cell {
            display: flex;
            align-items: center;
            max-width: 100%;
            overflow: hidden;

            &:first-child {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &:last-child {
                display: flex;
                justify-content: center;
                align-items: center;
                grid-gap: 10px;
                overflow: inherit;
            }

            span {
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
            }
        }

        &__cell-title {
            margin-right: 10px;
        }

        &__sort {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            color: #2e2b2b;
            opacity: 0.5;
            cursor: pointer;
        }

        &__arrow-up {
            width: 10px;
            height: 10px;
            transform: rotate(-180deg);
            margin-bottom: 1px;
        }

        &__arrow-down {
            width: 10px;
            height: 10px;
            margin-top: 1px;
        }

        .custom-checkbox .checkbox {
            border: 1px solid #70707080;
        }

        &__edit {
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.2s;

            &:hover {
                transform: scale(1.3);
                transition: 0.2s;
            }
        }

        &__dots {
            width: 24px;
            height: 16px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            transition: 0.2s;

            &:hover {
                transform: scale(1.3);
                transition: 0.2s;
            }
        }

        &__dot {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: black;
        }

        &__collapse {
            position: absolute;
            z-index: 1;
            right: -10px;
            top: 25px;
            width: 159px;
            // height: 190px;
            box-shadow: 0px 3px 15px #0000000f;
            border-radius: 5px;
            background-color: #fff;
            padding: 20px 0 17px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &__collapse-option {
            padding: 4px 16px;
            white-space: nowrap;
            font-size: 13px;

            &:hover {
                background-color: #f8f8f8;
            }
        }
    }
}
