@import '../../../../variables.scss';

.custom-dropdown.grey .label {
  color: #716a6a;
  font-weight: 400;
}
.space-tab {
  position: relative;
  &__title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 20px;
  }

  &__input-container {
    margin-top: 16px;
  }

  &__input-name {
    color: #2e2b2b;
    opacity: 0.5;
    font-size: 13px;
    margin-bottom: 8px;
    margin-top: 14px;
  }

  &__textarea {
    height: 138px;
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }

  &__cancel-btn {
    width: 200px;
    margin-right: 20px;
  }

  &__submit-btn {
    width: 200px;
  }

  .custom-input__topright-err {
    top: -31px;
  }

  &__tabs {
    display: flex;
  }

  &__subtitle {
    font-size: 16px;
    margin: 21px 0 16px 0;
  }

  &__tab {
    margin-right: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    height: 38px;
    width: 110px;
    cursor: pointer;
    color: #969595;
    border: 1px solid #969595;

    &_active {
      border: 1px solid $lightGreen;
      color: $lightGreen;
    }
  }

  &__switcher-container {
    display: flex;
    justify-content: space-between;
    margin-top: 13px;
    width: 100%;
    border-bottom: 1px solid #cccaca;
  }

  .last {
    border: none;
  }

  &__switcher-title {
    font-size: 13px;
    margin-bottom: 5px;
  }

  &__switcher-text {
    color: #2e2b2b;
    opacity: 0.5;
    padding-bottom: 13px;
    font-size: 13px;
  }

  &__switcher {
    margin-top: 13px;
  }
  &__sliders_container {
    margin-top: 20px;
    margin-bottom: 5px;
  }
}

.info-tab {
  &__tabs {
    display: flex;
  }

  &__tab {
    margin-right: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    height: 38px;
    width: 160px;
    cursor: pointer;
    color: #969595;
    border: 1px solid #969595;

    &_active {
    }
  }
}

.starting-location-tab {
  &__photo-wrapper {
    position: absolute;
    top: 130px;
    left: 50%;
    transform: translate(-50%);
    z-index: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__photo-background {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #fff;
    opacity: 0.5;
    z-index: 1;
  }

  &__photo-eye {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background-color: #fff;
    position: absolute;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    svg {
      width: 50%;
      height: 50%;
    }
  }

  &__photo-text {
    margin-top: 10px;
    font-weight: 600;
    font-size: 14px;
    position: absolute;
    bottom: 10px;
    z-index: 1;
  }

  &__button-wrapper {
    position: absolute;
    bottom: 53px;
    left: 50%;
    transform: translate(-50%);
    z-index: 1;
  }

  &__photo-wrapper {
    overflow: hidden;
    width: 240px;
    height: 102px;
    border-radius: 4px;

    img {
      height: 100%;
    }
  }

  &__set-location-button {
    width: 181px;
    height: 41px;
    position: relative;
  }

  &__tooltip {
    position: absolute;
    width: 250px;
    top: -110px;
    left: -35px;
    background-color: #fff;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    text-align: center;
    visibility: hidden;
    font-size: 13px;
    opacity: 0;
    transition: 0.5s;

    &_active {
      opacity: 1;
      visibility: visible;
      transition: 0.5s;
    }
  }
}

.tuning-tab {
  &__tabs {
    display: flex;
  }

  &__tab {
    margin-right: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    height: 38px;
    width: 120px;
    cursor: pointer;
    color: #969595;
    border: 1px solid #969595;

    &_active {
    }
  }
}

.poi-tab {
  &__table-item {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  &__font {
    .custom-input {
      font-size: 13px;
      .custom-input__input {
        &::placeholder {
          color: rgb(125, 141, 155);
          font-weight: 400;
        }
      }
    }
  }
  &__button {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 100px;
    z-index: 1;
    display: flex;
  }

  &__button-elem {
    box-shadow: 0px 3px 15px #0000000f;
    width: 52px;
    height: 52px;
    overflow: hidden;
    border: none;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
  }

  &__button-item {
    box-shadow: 0px 3px 15px #0000000f;
    width: 52px;
    height: 52px;
    overflow: hidden;
    border: none;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 16px 0 16px;

    svg {
      width: 50%;
      height: 50%;
    }
  }

  &__plus {
    object-fit: cover;
    width: 20px;
    height: 23px;
    transition: 0.2s;

    &_active {
      transform: rotate(45deg);
      transition: 0.2s;
    }
  }

  &__subtitle {
    margin: 3px 0 11px 0;
    font-size: 13px;
    color: #2e2b2b;
    opacity: 0.5;
    font-weight: 400;
  }

  &__input-container {
    height: 38px;
    margin-top: 10px;
  }

  &__table {
    margin-top: 10px;
    background-color: $lightGrey;
  }
  &__title {
    word-wrap: break-word;
    width: calc(100% - 30px);
  }

  &__table-head {
    font-size: 16px;
    color: #2e2b2b;
    font-weight: 600;
    border-bottom: 1px solid rgb(207, 207, 207);

    div {
      padding: 23px 0;
    }
  }

  &__table-category {
    display: flex;
    justify-content: center;
  }

  &__table-body {
    height: calc(100vh - 378px);
    overflow-y: auto;
  }

  &__table-row {
    display: grid;
    grid-template-columns: 1fr 4fr 8fr 1fr;
    grid-column-gap: 10px;
  }

  &__table-cell {
    display: flex;
    align-items: flex-start;
    padding: 10px 0;
    //min-height: 50px;
    word-break: break-word;
    &:first-child {
      justify-content: center;
      align-items: center;
    }

    &_disabled {
      opacity: 0.5;
    }
  }

  &__table-cell-nodata {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__table-eye {
    opacity: 0.5;
    width: 18px;
    height: 16px;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
      transition: 0.2s;
    }

    &_crossed {
      opacity: 0.3;
    }
  }

  &__dots {
    width: 24px;
    height: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: 0.2s;
    cursor: pointer;
    opacity: 0.5;

    &:hover {
      transform: scale(1.2);
      transition: 0.2s;
    }

    &_disabled {
      opacity: 0.3;
    }
  }

  &__dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: black;
  }

  &__collapse {
    position: absolute;
    z-index: 1;
    right: 20px;
    top: 37px;
    width: 100px;
    height: 114px;
    box-shadow: 0px 3px 15px #0000000f;
    border-radius: 5px;
    background-color: #fff;
    padding: 15px 0 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &--top {
      top: -100px;
    }
  }

  &__collapse-option {
    padding: 4px 16px;
    white-space: nowrap;
    font-size: 13px;
    cursor: pointer;

    &:hover {
      background-color: #f8f8f8;
    }
  }

  &__tooltip {
    position: absolute;
    width: 240px;
    top: -90px;
    left: -96px;
    background-color: #fff;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    text-align: center;
    visibility: hidden;
    font-size: 13px;
    opacity: 0;
    transition: 0.5s;

    &_active {
      opacity: 1;
      visibility: visible;
      transition: 0.5s;
    }
  }
}

.take_photo-tab {
  width: 0;
  &__nav {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 13px;
    position: absolute;
    top: 130px;
    left: 50%;
    transform: translate(-50%);
    z-index: 1;
  }
  &__setting {
    position: absolute;
    top: 160px;
    left: 50%;
    margin-left: -185px;
    transform: translateX(-50%);
    max-width: 285px;
    width: 100%;
    border: none;
    border-radius: 5px;
    z-index: 1;
    color: #2e2b2b;
    font-size: 13px;
    padding: 16px 20px 22px 11px;
    box-shadow: 0px 3px 15px #0000000f;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-gap: 22px;
  }
  &__switch {
    display: flex;
    justify-content: space-between;
    width: 100%;
    p {
      max-width: 208px;
      width: 100%;
    }
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    //padding: 5px 7px;
    cursor: pointer;
    svg {
      max-width: 14px;
    }
    &--active {
    }
    // &:hover {
    //   svg {
    //     fill: $placeholder;
    //   }
    // }
  }
  &__small {
    width: 9px;
    fill: #2e2b2b;
  }
  &__normal {
    width: 14px;
  }
  &__mode {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 40px;
    height: 23px;
    cursor: pointer;
    &_item {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      box-shadow: -1px 0px 1px #707070;
      min-width: 40px;
      &:first-child {
        box-shadow: none;
      }
      &--active {
      }
    }
    &_btn {
      display: flex;
      height: 100%;
      align-items: center;
      min-width: 20px;
      &:first-child {
        justify-content: flex-end;
      }
      &:last-child {
        justify-content: flex-start;
      }
    }
    &_slider {
      height: 100%;
      display: flex;
      align-items: center;
      width: 88px;
    }
  }
  &__modal {
    position: absolute;
    bottom: 140px;
    left: 50%;
    border: none;
    border-radius: 5px;
    transform: translate(-50%);
    z-index: 1;
    color: #2e2b2b;
    font-size: 13px;
    padding: 12px 15px;
    box-shadow: 0px 3px 15px #0000000f;
    background: rgba(220, 210, 208, 0.7);
  }
  &__btn {
    position: absolute;
    bottom: 70px;
    left: 50%;
    width: 52px;
    height: 52px;
    border: none;
    outline: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%);
    z-index: 1;
    cursor: pointer;
    background-color: #ffffff;
    box-shadow: 0px 3px 15px #0000000f;
  }
  &__vert {
    //position: absolute;
    display: flex;
    &:after {
      content: '';
      position: absolute;
      width: 1px;
      top: 95px;
      height: 87.7%;
      background-color: #d8d5d3;
      z-index: 1;
      left: calc(100% - 65%);
    }
    &:before {
      content: '';
      position: absolute;
      width: 1px;
      top: 95px;
      height: 87.7%;
      background-color: #d8d5d3;
      z-index: 1;
      right: calc(100% - 65%);
    }
  }
  &__goriz {
    //position: absolute;
    display: flex;
    &:after {
      content: '';
      position: absolute;
      width: 1787px;
      left: 72px;
      top: calc(100% - 65%);
      height: 1px;
      background-color: #d8d5d3;
      z-index: 1;
    }
    &:before {
      content: '';
      position: absolute;
      width: 1787px;
      height: 1px;
      top: calc(100% - 30%);
      background-color: #d8d5d3;
      z-index: 1;
      left: 72px;
    }
  }
}
.download-tab {
  height: 100%;
  padding-left: 70px;
  margin-right: -40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  &__title {
    margin-bottom: 54px;
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__sort {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 15px;
    max-width: 600px;
    width: 100%;
    &-search {
      height: 38px;
      max-width: 260px;
      width: 100%;
    }
    &-sorting {
      max-width: 220px;
      width: 100%;
      .custom-dropdown.grey .label {
        color: #716a6a;
      }
    }
  }
  &__centered {
    text-align: center;
    width: 100%;
  }
  &__image {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-gap: 15px;
    margin-top: 30px;
    &-item {
      cursor: pointer;
      width: 308px;
      height: 183px;
      position: relative;
      box-shadow: 0px 3px 15px #0000000a;
      border-radius: 3px;
      flex-grow: 1;
      max-width: 400px;
      transition: opacity 0.3s linear 0.1s;
      img {
        opacity: 0.5;
      }
      .custom-checkbox {
        position: absolute;
        top: -5px;
        right: -15px;
        .checkbox {
          background-color: white;
        }
      }
      &--hide {
        img {
          opacity: 1;
        }
        &:hover {
          img {
            opacity: 0.5;
          }
        }
      }
    }
    &-img {
      width: 100%;
      object-position: center;
      object-fit: cover;
      height: 100%;
    }
  }
  &__actions {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 20px;
    height: 25px;
    svg {
      width: 14px;
      opacity: 0.5;
    }
    &-item {
      // &:hover {
      //   svg {
      //     fill: $placeholder;
      //     cursor: pointer;
      //   }
      // }
    }
  }
  .pagination {
    flex-grow: 1;
    align-items: flex-end;
  }
}
.access-settings {
  &__search {
    height: 38px;
    width: 306px;
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: auto;
  }
  &__tabs {
    justify-content: space-between;
  }
  &__tab {
    display: flex;
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    grid-gap: 20px;
  }
  &__btn {
    width: 52px;
    height: 52px;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 3px 15px #0000000f;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 20px;
    }
    &:hover {
      border: 1px solid rgba(201, 192, 192, 0.5);
      transform: scale(1.2);
      transition: 0.3s;
    }
  }
}
.access-table {
  width: 100%;
  font-size: 16px;
  min-height: calc(100vh - 410px);

  &__row {
    width: 100%;
    margin: 15px 0;
    cursor: pointer;
    min-height: 37px;
    display: grid;
    grid-template-columns: 2fr 2fr 3fr 2fr 1fr;
    grid-gap: 5px;

    &:hover {
      background-color: #f8f8f8;
    }
  }

  &__def-row {
    width: 100%;
    height: 23px;
    color: #2e2b2b;
    font-weight: 600;
    margin-bottom: 16px;
  }

  &__message {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__cell {
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow: hidden;

    &:first-child {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &:last-child {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: inherit;
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      white-space: nowrap;
    }
  }

  &__cell-title {
    margin-right: 10px;
  }

  &__sort {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: #2e2b2b;
    opacity: 0.5;
    cursor: pointer;
  }

  &__arrow-up {
    width: 10px;
    height: 10px;
    transform: rotate(-180deg);
    margin-bottom: 1px;
  }

  &__arrow-down {
    width: 10px;
    height: 10px;
    margin-top: 1px;
  }

  .custom-checkbox .checkbox {
    border: 1px solid #70707080;
  }

  &__edit {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;

    &:hover {
      transform: scale(1.3);
      transition: 0.2s;
    }
  }

  &__dots {
    width: 24px;
    height: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: 0.2s;

    &:hover {
      transform: scale(1.3);
      transition: 0.2s;
    }
  }

  &__dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: black;
  }

  &__collapse {
    position: absolute;
    z-index: 1;
    right: -10px;
    top: 25px;
    width: 160px;
    height: 82px;
    box-shadow: 0px 3px 15px #0000000f;
    border-radius: 5px;
    background-color: #fff;
    padding: 20px 0 17px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__collapse-option {
    padding: 4px 16px;
    white-space: nowrap;
    font-size: 13px;

    &:hover {
      background-color: #f8f8f8;
    }
  }
}
.categories-tab__table-row {
  display: grid;
  grid-template-columns: 10fr 1fr;
}
.categories-tab__table-cell {
  justify-content: center;
  &:first-child {
    justify-content: flex-start;
  }
}
.categories-tab__table-body {
  height: calc(100vh - 430px);
  padding-left: 16px;
  background-color: #f8f8f8;
  padding-top: 10px;
}
.categories-tab__collapse {
  height: 82px;
  width: 160px;
}
.categories-tab__table-head {
  margin-top: 38px;
  padding: 22px 16px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #cfcfcf;
}
.categories-tab {
  &__edit {
  }
  &__edit-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 5px;
    width: fit-content;
    border-bottom: 2px solid #d6d6d6;
  }
  &__input {
    height: 45px;
    background-color: #f8f8f8;
    font-size: 16px;
    &::placeholder {
      opacity: 0.5;
      font-size: 13px;
    }
  }
  &__btn {
    width: 25px;
    height: 25px;
    border: none;
    outline: none;
    background: transparent;
    color: #a3a2a2;
    cursor: pointer;
    svg {
      fill: #a3a2a2;
    }
    &:hover {
      color: black;
      svg {
        fill: black;
      }
    }
  }
  &__error {
  }
}
.mini-map {
  &__setting {
    margin-top: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 41px;
    .custom-button {
      width: 181px;
    }
  }
  &__floors {
    display: flex;
    font-size: 13px;
    align-items: center;
    grid-gap: 20px;
    height: 100%;
    span {
      white-space: nowrap;
    }
    .custom-dropdown {
      width: 107px;
    }
  }
  .dropzone {
    margin-top: 25px;
    background: #f6f7f8;
    display: flex;
    align-items: center;
    flex-direction: column;
    grid-gap: 5px;
    padding: 28px 0;
    cursor: pointer;
    &__title {
      font-size: 16px;
      text-decoration: underline;
    }
    &__subtitle {
      font-size: 13px;
    }
    &__svg {
      width: 63px;
      height: 50px;
      fill: #555555;
    }
  }
  .zoom {
    position: fixed;
    overflow: hidden;
    z-index: 1;
    right: 23px;
    top: 95px;
    width: calc(100vw - 634px);
    height: calc(100vh - 120px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .zoom-content {
    position: relative;
  }
  .img {
    max-width: 100%;
    max-height: calc(100vh - 120px);
  }
  .draggble-img {
    //position: absolute;
    //height: 100%;
    // width: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .zooms {
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
    .img {
      width: 100%;
      height: 100%;
    }
  }
  .wrapper__zooms {
    background: #1e2023;
    margin: 0 auto;
    width: 100%;
    height: 90%;
  }
  .tools {
    &__back {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 15px;
      margin-top: 20px;
      margin-bottom: 5px;
    }
    &__button {
      border-radius: 3px;
      outline: none;
      border: none;
      user-select: none;
      cursor: pointer;
      padding: 5px 10px;
      color: white;
      font-size: 13px;
      &:hover {
        opacity: 0.7;
      }
    }
    &__size {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 148px;
      grid-gap: 10px;
      .custom-slider {
        margin-bottom: 0;
        margin-top: 5px;
      }
    }
    &__btn {
      width: 9px;
      outline: none;
      border: none;
      user-select: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      padding: 0 5px;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
  &__pois {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  &__poi {
    cursor: pointer;
    height: 16px;
    position: absolute;
    width: 16px;
  }
  &__sweep {
    width: 16px;
    height: 16px;
    border: 3px solid #dd4e67;
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    opacity: 0.7;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #dd4e67;
    }
  }
}
.space-tab__flex {
  display: flex;
  flex-direction: column;
  grid-gap: 17px;
}

.space-tab__autocomplete-error {
  border: 2px solid #f2574d;
  position: relative;
  &-text {
    position: absolute;
    top: -31px;
    right: 0px;
    font-size: 14px;
    color: red;
    display: flex;
  }
}

.point-drag {
  position: absolute;
  background: #97c01e8f;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 1;
  display: flex;
  border: none;
  box-shadow: 0 0 0 3px #97c01e8f;
  transform: translateY(-25px) translateX(-25px);
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
}
