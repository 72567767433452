@import '../../../variables.scss';

.customize-page {
  &__label {
    font-size: 20px;
    font-weight: 600;
    color: $black;
    margin-bottom: 11px;
  }

  &__left-right-container {
    display: flex;
    width: 100%;
    margin-top: 25px;
  }

  &__left-container {
    width: 495px;
    margin: 0px 93px 20px 0px;
  }

  &__color-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 10px;
    position: relative;
  }

  &__hex {
    min-width: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    background: $lightGrey;
    border-radius: 4px;
    font-size: 13px;
    color: $placeholder;
  }

  &__picker {
    position: absolute;
    top: 0;
    left: 147px;
    width: max-content;
    z-index: 1;
  }

  &__right-container {
    width: 495px;
  }

  &__last-input-container {
    width: 495px;
    margin-bottom: 50px;
  }

  &__left-container,
  &__right-container,
  &__last-input-container {
    .custom-input {
      height: 38px;
    }
  }

  &__submit-btn {
    height: 41px;
    width: 181px;
    margin-bottom: 23px;
  }
}
